import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Image from "../components/Image"

const ValuePropositionTwoColumns = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "vertical-side.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section id="how-does-it-work" className="section py-75">
      <div className="container columns">
        <div className="column is-flex is-flex-direction-column">
          <Image className="vertical-rounded-right-border" data={data} />
        </div>

        <div className="column ml-6 is-flex is-flex-direction-column is-justify-content-center">
          <div>
            <h2 className="is-size-2 title mb-6 ml-70">Comment ça marche ?</h2>
          </div>
          <div className="is-flex mb-6">
            <div className="round-tag has-text-white has-background-primary is-size-4 has-text-weight-medium p-2 is-flex is-justify-content-center mr-5">
              1
            </div>
            <div>
              <h4 className="title is-4">
                MonApport.com investit dans votre appartement.
              </h4>
              <p className="subtitle">
                Vous ne disposez pas de l’apport nécessaire pour devenir
                propriétaire ? MonApport.com vous apporte entre 10% à 15% de la
                valeur du bien à titre d’apport.
              </p>
            </div>
          </div>
          <div className="is-flex mb-6">
            <div className="round-tag has-text-white has-background-primary is-size-4 has-text-weight-medium p-2 is-flex is-justify-content-center mr-5">
              2
            </div>
            <div>
              <h4 className="title is-4">Vous êtes propriétaire du bien :</h4>
              <p className="subtitle">
                En contrepartie de l’investissement de 10%, MonApport.com
                devient propriétaire de 15% du bien. MonApport.com est donc
                copropriétaire minoritaire. A ce titre, MonApport.com
                n’interfère en rien dans la vie du bien. Habitation, rénovation,
                location… : vous êtes chez vous.
              </p>
            </div>
          </div>
          <div className="is-flex">
            <div className="round-tag has-text-white has-background-primary is-size-4 has-text-weight-medium p-2 is-flex is-justify-content-center mr-5">
              3
            </div>
            <div>
              <h4 className="title is-4">
                C’est au moment de la revente du bien que MonApport.com récupère
                ses fonds :
              </h4>
              <p className="subtitle">
                Vous êtes le propriétaire, vous décidez seul du moment de la
                vente du bien dans un délai de 10 ans. Si vous souhaitez
                conserver le bien à l’issue de ce délai, vous pourrez racheter
                les parts de MonApport.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ValuePropositionTwoColumns
