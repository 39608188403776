import React, { useEffect } from "react"
import { Link } from "gatsby"

const Nav = () => {
  useEffect(() => {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    )

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener("click", () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target
          const $target = document.getElementById(target)

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle("is-active")
          $target.classList.toggle("is-active")
        })
      })
    }
  })

  return (
    <nav
      className="navbar py-1 has-background-white is-fixed-top has-boxshadow"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <div className="navbar-item is-size-4 has-text-weight-semibold">
          <Link className="has-text-black" to="/">
            MonApport.com
          </Link>
        </div>

        <a
          role="button"
          className="navbar-burger mt-2"
          aria-label="menu"
          aria-expanded="false"
          data-target="navigation-menu-items"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navigation-menu-items" className="navbar-menu">
        <div className="navbar-start">
          <div className="navbar-item">
            <Link
              to="#how-does-it-work"
              className="has-text-weight-normal has-text-black pt-10 is-size-7"
            >
              Comment ça marche
            </Link>
          </div>
          <div className="navbar-item">
            <Link
              to="#calculator"
              className="has-text-weight-normal has-text-black pt-10 is-size-7"
            >
              Calculez votre budget
            </Link>
          </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <a
              href="mailto:contact@monapport.com"
              className="has-text-weight-normal has-text-black pt-10 is-size-7"
            >
              contact@monapport.com
            </a>
          </div>
          <div className="navbar-item">
            <a
              href="http://divorce.monapport.com/"
              className="button is-outlined has-boxshadow is-primary has-text-weight-bold is-size-7"
              target="_blank"
            >
              Séparation / Divorce
            </a>
          </div>
          <div className="navbar-item">
            <Link
              to="#typeform"
              className="button has-boxshadow is-primary has-text-weight-bold is-size-7"
            >
              Déposer votre dossier
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
