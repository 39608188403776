import React from "react"

import Layout from "../components/Layout"
import Hero from "../components/Hero"
import BasicSection from "../components/BasicSection"
import ValuePropositionTwoColumns from "../components/ValuePropositionTwoColumns"
import ValueProposition from "../components/ValueProposition"
import Faq from "../components/Faq"
import Form from "../components/Form"
import Footer from "../components/Footer"
import SEO from "../components/Seo"
import Slider from "../components/Slider"
import "../styles/styles.scss"

const questions = [
  {
    title: "Qui est MonApport.com ?",
    answer:
      "MonApport.com est un intermédiaire de confiance entre des investisseurs institutionnels cherchant à investir dans l’immobilier et des acquéreurs cherchant à devenir propriétaire.",
  },
  {
    title: "En quoi cela est-il différent d’un prêt bancaire ?",
    answer:
      "MonApport.com n’est pas une banque. MonApport.com propose une solution de co-investissement.\nPas de mensualité, pas de loyer, MonApport.com devient co-propriétaire du bien à vos côtés. C’est au moment de la revente du bien que MonApport.com rentabilise son investissement initial.",
  },
  {
    title: "Quels sont les services MonApport.com ?",
    answer: `MonApport.com vous aide à plusieurs niveaux :\n\n- Nous vous apportons un deuxième avis sur votre projet : si nous investissons avec vous, c’est que nous y croyons.\n\n- Vous n’arrivez pas à débloquer votre crédit immobilier : grâce à notre investissement, vous disposerez de l’apport nécessaire pour devenir propriétaire.\n\n- Vos mensualités sont trop lourdes : grâce à MonApport.com, vous allez pouvoir diminuer les mensualités de votre crédit.`,
  },
  {
    title: "Qui peut bénéficier des services de MonApport.com ?",
    answer:
      "MonApport.com a été conçue pour tous ceux qui ne disposent pas de l’épargne suffisante pour accéder à la propriété. Notre service n’est actuellement proposé qu’en Ile de France.",
  },
  {
    title: "Comment bénéficier du service MonApport.com ?",
    answer:
      "C’est très simple : vous remplissez le formulaire (liens) et nous prendrons contact avec vous.",
  },
  {
    title: "Qui paye les mensualités du prêt ? Les charges ? Les travaux ?",
    answer:
      "Vous êtes propriétaire. C’est donc à vous de payer l’intégralité des charges afférentes à l’utilisation du bien.",
  },
]

const Calculator = ({ className }) => {
  return (
    <div id="calculator" className={`section ${className}`}>
      <div className="container">
        <h2 className="title is-size-2 has-text-centered">
          Calculez votre budget avec MonApport.com
        </h2>
        <Slider />
      </div>
    </div>
  )
}

const IndexPage = () => (
  <Layout>
    <SEO title="Accueil" />
    <Hero />
    <BasicSection
      title={"MonApport.com"}
      subtitle={
        "Pas de mensualités, pas de loyer, ce n'est pas un prêt.\nMonApport.com investit 10% du montant de votre bien afin de vous permettre de devenir propriétaire."
      }
    />
    <ValuePropositionTwoColumns />
    <ValueProposition />
    <Calculator className="has-background-white-bis" />
    <Form url="https://form.typeform.com/to/Jjg6RfLR?typeform-medium=embed-snippet" />
    <Faq questions={questions} className="has-background-white-ter" />
    <Footer />
  </Layout>
)

export default IndexPage
