import React from "react"

const SimpleCard = ({ iconSrc, alt, title, text }) => {
  return (
    <div className="card is-shadowless is-third is-flex-grow-1">
      <div className="card-image pt-5">
        <figure className="image h-100 is-flex is-justify-content-center is-align-content-center">
          <img
            className="m-0 svg-primary"
            src={iconSrc}
            alt={alt}
            style={{ width: `75px` }}
          />
        </figure>
      </div>
      <div className="card-content">
        <div className="title is-size-4">{title}</div>
        <div className="content is-size-6">{text}</div>
      </div>
    </div>
  )
}

export default SimpleCard
