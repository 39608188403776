import React from "react"

const BasicSection = ({ title, subtitle }) => {
  const addBreaklines = string => {
    return string.split("\n").map(str => (
      <>
        {str}
        <br />
      </>
    ))
  }

  return (
    <div className="section mt-50">
      <div className="container">
        <div className="columns is-flex is-justify-content-center">
          <div className="column is-three-quarters mb-6 has-text-centered">
            <h2 className="title is-2 mb-6">{addBreaklines(title)}</h2>
            <h3 className="subtitle is-3">{addBreaklines(subtitle)}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicSection
