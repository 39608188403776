import React, { useRef, useEffect, useState } from "react"

import "./Slider.scss"

const Slider = () => {
  const [thumbSize, setThumbSize] = useState()
  const [valueWithout, setValueWithout] = useState(0)
  const [valueWith, setValueWith] = useState(0)
  const [apportMAP, setApportMAP] = useState(0)

  const formatPrice = price => {
    return (
      price
        .toString()
        .split("")
        .reverse()
        .join("")
        .match(/.{1,3}/g)
        .join(" ")
        .split("")
        .reverse()
        .join("") + "€"
    )
  }

  const [apport, setApport] = useState({
    apportInputValue: "0",
    apportInput: {
      0: "0€",
      1: "1 000€",
      2: "2 000€",
      3: "3 000€",
      4: "4 000€",
      5: "5 000€",
      6: "6 000€",
      7: "7 000€",
      8: "8 000€",
      9: "9 000€",
      10: "1 000€",
      11: "11 000€",
      12: "12 000€",
      13: "13 000€",
      14: "14 000€",
      15: "15 000€",
      16: "16 000€",
      17: "17 000€",
      18: "18 000€",
      19: "19 000€",
      20: "20 000€",
      21: "21 000€",
      22: "22 000€",
      23: "23 000€",
      24: "24 000€",
      25: "25 000€",
      26: "26 000€",
      27: "27 000€",
      28: "28 000€",
      29: "29 000€",
      30: "30 000€",
      31: "31 000€",
      32: "32 000€",
      33: "33 000€",
      34: "34 000€",
      35: "35 000€",
      36: "36 000€",
      37: "37 000€",
      38: "38 000€",
      39: "39 000€",
      40: "40 000€",
      41: "41 000€",
      42: "42 000€",
      43: "43 000€",
      44: "44 000€",
      45: "45 000€",
      46: "46 000€",
      47: "47 000€",
      48: "48 000€",
      49: "49 000€",
      50: "50 000€",
      51: "51 000€",
      52: "52 000€",
      53: "53 000€",
      54: "54 000€",
      55: "55 000€",
      56: "56 000€",
      57: "57 000€",
      58: "58 000€",
      59: "59 000€",
      60: "60 000€",
      61: "61 000€",
      62: "62 000€",
      63: "63 000€",
      64: "64 000€",
      65: "65 000€",
      66: "66 000€",
      67: "67 000€",
      68: "68 000€",
      69: "69 000€",
      70: "70 000€",
      71: "71 000€",
      72: "72 000€",
      73: "73 000€",
      74: "74 000€",
      75: "75 000€",
      76: "76 000€",
      77: "77 000€",
      78: "78 000€",
      79: "79 000€",
      80: "80 000€",
      81: "81 000€",
      82: "82 000€",
      83: "83 000€",
      84: "84 000€",
      85: "85 000€",
      86: "86 000€",
      87: "87 000€",
      88: "88 000€",
      89: "89 000€",
      90: "90 000€",
      91: "91 000€",
      92: "92 000€",
      93: "93 000€",
      94: "94 000€",
      95: "95 000€",
      96: "96 000€",
      97: "97 000€",
      98: "98 000€",
      99: "99 000€",
      100: "100 000€",
    },
  })

  const [debtCapacity, setDebtCapacity] = useState({
    debtInputValue: "30",
    debtInput: {
      0: "100 000€",
      1: "110 000€",
      2: "120 000€",
      3: "130 000€",
      4: "140 000€",
      5: "150 000€",
      6: "160 000€",
      7: "170 000€",
      8: "180 000€",
      9: "190 000€",
      10: "200 000€",
      11: "210 000€",
      12: "220 000€",
      13: "230 000€",
      14: "240 000€",
      15: "250 000€",
      16: "260 000€",
      17: "270 000€",
      18: "280 000€",
      19: "290 000€",
      20: "300 000€",
      21: "310 000€",
      22: "320 000€",
      23: "330 000€",
      24: "340 000€",
      25: "350 000€",
      26: "360 000€",
      27: "370 000€",
      28: "380 000€",
      29: "390 000€",
      30: "400 000€",
      31: "410 000€",
      32: "420 000€",
      33: "430 000€",
      34: "440 000€",
      35: "450 000€",
      36: "460 000€",
      37: "470 000€",
      38: "480 000€",
      39: "490 000€",
      40: "500 000€",
      41: "510 000€",
      42: "520 000€",
      43: "530 000€",
      44: "540 000€",
      45: "550 000€",
      46: "560 000€",
      47: "570 000€",
      48: "580 000€",
      49: "590 000€",
      50: "600 000€",
      51: "610 000€",
      52: "620 000€",
      53: "630 000€",
      54: "640 000€",
      55: "650 000€",
      56: "660 000€",
      57: "670 000€",
      58: "680 000€",
      59: "690 000€",
      60: "700 000€",
      61: "710 000€",
      62: "720 000€",
      63: "730 000€",
      64: "740 000€",
      65: "750 000€",
      66: "760 000€",
      67: "770 000€",
      68: "780 000€",
      69: "790 000€",
      70: "800 000€",
      71: "810 000€",
      72: "820 000€",
      73: "830 000€",
      74: "840 000€",
      75: "850 000€",
      76: "860 000€",
      77: "870 000€",
      78: "880 000€",
      79: "890 000€",
      80: "900 000€",
      81: "910 000€",
      82: "920 000€",
      83: "930 000€",
      84: "940 000€",
      85: "950 000€",
      86: "960 000€",
      87: "970 000€",
      88: "980 000€",
      89: "990 000€",
      90: "1 000 000€",
    },
  })

  const [monApport, setMonApport] = useState({
    monApportInputValue: "9",
    monApportInput: {
      0: "1%",
      1: "2%",
      2: "3%",
      3: "4%",
      4: "5%",
      5: "6%",
      6: "7%",
      7: "8%",
      8: "9%",
      9: "10%",
      10: "11%",
      11: "12%",
      12: "13%",
      13: "14%",
      14: "15%",
      15: "16%",
      16: "17%",
      17: "18%",
      18: "19%",
      19: "20%",
    },
  })

  const sliderApport = useRef()
  const sliderApportValue = useRef()

  const sliderDebt = useRef()
  const sliderDebtValue = useRef()

  const sliderMonApport = useRef()
  const sliderMonApportValue = useRef()

  const setValues = () => {
    const a = parseFloat(
      apport.apportInput[apport.apportInputValue]
        .replaceAll("€", "")
        .replaceAll(" ", "")
        .replaceAll("k", "000"),
      0
    )

    const b = parseFloat(
      debtCapacity.debtInput[debtCapacity.debtInputValue]
        .replaceAll("€", "")
        .replaceAll(" ", ""),
      0
    )

    const c = parseFloat(
      (a + b) *
        parseFloat(
          monApport.monApportInput[monApport.monApportInputValue].replaceAll(
            "%",
            ""
          ) / 100
        )
    )

    setApportMAP(Math.round(c))
    setValueWith(Math.round(parseFloat((a + b + c) / 1.08), 0))
    setValueWithout(Math.round(parseFloat((a + b) / 1.08), 0))
  }

  const handleApportSlide = e => {
    setApport({ ...apport, apportInputValue: e.target.value })
    handleSliderApportValuePosition(e.target)
    setValues()
  }

  const handleSliderApportValuePosition = input => {
    const multiplier = input.value / input.max
    const thumbOffset = thumbSize * multiplier
    const apportInputOffset =
      (thumbSize - sliderApportValue.current.clientWidth) / 2
    sliderApportValue.current.style.left =
      input.clientWidth * multiplier - thumbOffset + apportInputOffset + "px"
  }

  const handleDebtSlide = e => {
    setDebtCapacity({ ...debtCapacity, debtInputValue: e.target.value })
    handleSliderDebtValuePosition(e.target)
    setValues()
  }

  const handleSliderDebtValuePosition = input => {
    const multiplier = input.value / input.max
    const thumbOffset = thumbSize * multiplier
    const debtInputOffset =
      (thumbSize - sliderDebtValue.current.clientWidth) / 2
    sliderDebtValue.current.style.left =
      input.clientWidth * multiplier - thumbOffset + debtInputOffset + "px"
  }

  const handleMonApportSlide = e => {
    setMonApport({ ...monApport, monApportInputValue: e.target.value })
    handleSliderMonApportValuePosition(e.target)
    setValues()
  }

  const handleSliderMonApportValuePosition = input => {
    const multiplier = input.value / input.max
    const thumbOffset = thumbSize * multiplier
    const monApportInputOffset =
      (thumbSize - sliderMonApportValue.current.clientWidth) / 2
    sliderMonApportValue.current.style.left =
      input.clientWidth * multiplier - thumbOffset + monApportInputOffset + "px"
  }

  useEffect(() => {
    sliderApport.current.setAttribute("min", 0)
    sliderApport.current.setAttribute(
      "max",
      Object.keys(apport.apportInput).length - 1
    )

    sliderDebt.current.setAttribute("min", 0)
    sliderDebt.current.setAttribute(
      "max",
      Object.keys(debtCapacity.debtInput).length - 1
    )

    sliderMonApport.current.setAttribute("min", 0)
    sliderMonApport.current.setAttribute(
      "max",
      Object.keys(monApport.monApportInput).length - 1
    )

    setThumbSize(
      parseInt(
        window
          .getComputedStyle(sliderApportValue.current)
          .getPropertyValue("--thumb-size"),
        10
      )
    )
    handleSliderApportValuePosition(sliderApport.current)
    handleSliderDebtValuePosition(sliderDebt.current)
    handleSliderMonApportValuePosition(sliderMonApport.current)
    setValues()
  }, [
    apport.apportInput,
    debtCapacity.debtInput,
    monApport.monApportInput,
    handleSliderApportValuePosition,
    handleSliderDebtValuePosition,
    handleSliderMonApportValuePosition,
    setValues,
  ])

  return (
    <div id="calculator" className="section my-6 pricing">
      <div className="pricing-slider center-content">
        <label className="form-slider">
          <span>Votre apport :</span>
          <input
            type="range"
            ref={sliderApport}
            defaultValue={apport.apportInputValue}
            onChange={handleApportSlide}
          />
        </label>
        <div ref={sliderApportValue} className="pricing-slider-value">
          {apport.apportInput && apport.apportInput[apport.apportInputValue]}
        </div>
      </div>

      <div className="pricing-slider center-content">
        <label className="form-slider">
          <span>Votre capacité d'endettement :</span>
          <input
            type="range"
            ref={sliderDebt}
            defaultValue={debtCapacity.debtInputValue}
            onChange={handleDebtSlide}
          />
        </label>
        <div ref={sliderDebtValue} className="pricing-slider-value">
          {debtCapacity.debtInput &&
            debtCapacity.debtInput[debtCapacity.debtInputValue]}
        </div>
      </div>

      <div className="pricing-slider center-content">
        <label className="form-slider">
          <span>Investissement MonApport.com : {formatPrice(apportMAP)}</span>
          <input
            type="range"
            ref={sliderMonApport}
            defaultValue={monApport.monApportInputValue}
            onChange={handleMonApportSlide}
          />
        </label>
        <div ref={sliderMonApportValue} className="pricing-slider-value">
          {monApport.monApportInput &&
            monApport.monApportInput[monApport.monApportInputValue]}
        </div>
      </div>

      <div className="pricing-items mt-6">
        <div className="card mt-5 mx-5">
          <div className="pricing-item-inner">
            <div className="pricing-item-content">
              <div className="pricing-item-header has-text-centered">
                <div className="pricing-item-title">
                  Valeur du bien sans MonApport :
                </div>
                <div className="pricing-item-apport">
                  <span className="pricing-item-apport-currency"></span>
                  <span className="pricing-item-apport-amount">
                    {formatPrice(valueWithout)}
                  </span>
                </div>
              </div>
            </div>
            <div className="pricing-item-cta"></div>
          </div>
        </div>

        <div className="card mt-5 mx-5">
          <div className="pricing-item-inner">
            <div className="pricing-item-content">
              <div className="pricing-item-header has-text-centered">
                <div className="pricing-item-title">
                  Valeur du bien avec MonApport :
                </div>
                <div className="pricing-item-apport">
                  <span className="pricing-item-apport-amount has-text-primary has-text-weight-semibold">
                    {formatPrice(valueWith)}
                  </span>
                </div>
              </div>
            </div>
            <div className="pricing-item-cta"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider
