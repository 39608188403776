import React, { useState } from "react"

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div className="accordion-wrapper">
      <div
        className={`is-size-4 has-text-weight-bold p-5  accordion-title ${
          isOpen ? "open" : ""
        }`}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content is-size-4 mb-6">{children}</div>
      </div>
    </div>
  )
}

const Faq = ({ questions, className }) => {
  return (
    <div className={`section ${className}`}>
      <div className="container columns">
        <div className="column">
          <div className="title has-text-centered is-size-1">FAQ</div>
        </div>
        <div className="column is-two-thirds">
          {questions.map(question => (
            <div>
              <Accordion title={question.title}>
                {question.answer.split("\n").map(str => (
                  <>
                    {str}
                    <br />
                  </>
                ))}
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Faq
