import React from "react"

const Footer = () => {
  return (
    <div className="section has-background-black">
      <div className="container">
        <div className="columns is-hidden-touch">
          <div className="column">
            <a className="has-text-white" href="mailto:contact@monapport.com">
              contact@monapport.com
            </a>
          </div>
          <div className="column has-text-right">
            <a
              href="https://pretotype.fr"
              className="has-text-pretotype"
              target="_blank"
              rel="noreferrer"
            >
              confectionné par pretotype.fr
            </a>
          </div>
        </div>
        <div className="columns is-hidden-desktop">
          <div className="column has-text-centered">
            <a className="has-text-white" href="mailto:contact@monapport.com">
              contact@monapport.com
            </a>
          </div>
          <div className="column has-text-centered">
            <a
              href="https://pretotype.fr"
              className="has-text-primary"
              target="_blank"
              rel="noreferrer"
            >
              confectionné par pretotype.fr
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
