import React from "react"
import { Link } from "gatsby"

import SimpleCard from "../components/SimpleCard"

import candidateSvg from "../images/candidature.svg"
import fileSvg from "../images/dossier.svg"
import creditSvg from "../images/credit.svg"

const ValueProposition = () => {
  return (
    <section className="section has-text-centered">
      <h2 className="title is-size-2">Accédez a la propriété en 3 étapes</h2>
      <h3 className="subtile is-size-4">
        Nos conseillers vous contacteront pour vous accompagner durant toutes
        les étapes
      </h3>
      <div className="is-flex is-justify-content-space-between is-flex-wrap-wrap pt-6">
        <SimpleCard
          iconSrc={candidateSvg}
          alt="icon candidature"
          title="Déposez votre candidature :"
          text="Expliquez-nous votre projet en quelques mots. Nous reviendrons rapidement vers vous pour vous indiquer si votre dossier est retenu."
        />
        <SimpleCard
          iconSrc={fileSvg}
          alt="icon dossier"
          title="Complétez votre dossier :"
          text="Si votre projet est retenu, nous vous accompagnerons dans la conception de votre dossier de financement."
        />
        <SimpleCard
          iconSrc={creditSvg}
          alt="icon credit"
          title="Débloquez votre crédit :"
          text="Grâce à l’investissement de MonApport.com, vous pourrez accéder à votre crédit immobilier aux meilleures conditions. Bienvenue chez vous."
        />
      </div>
      <Link
        to="#typeform"
        className="button has-boxshadow is-medium has-text-weight-bold is-primary mt-6"
      >
        Déposez votre dossier
      </Link>
    </section>
  )
}

export default ValueProposition
